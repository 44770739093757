import React from "react";
import { Card, Text } from "theme-ui";
import Section from "@components/Section";
const Commitment = (props) => (
  <Section aside title="" {...props}>
    <Card variant="paper">
      {" "}
      <Text variant="p">
        Please, feel free to reach out, I am open to chatting about work, side
        projects or product management best practices in general.
      </Text>
    </Card>
  </Section>
);
export default Commitment;
